<template>
  <div class="question-page">
    {{ questionsWithAnswers }}
    <!-- Loop through each question with its answers -->
    <div
      v-for="(question, index) in questionsWithAnswers"
      :key="question.id"
      class="question"
    >
      <b-card
        class="mb-3 p-3 custom-card"
      >
        <!-- Display the question text -->
        <h5>{{ question.description || question.name }}</h5>

        <!-- Render answers as checkboxes for multiple-choice questions -->
        <div v-if="question.answers && question.answers.length > 0">
          <div
            v-for="answer in question.answers"
            :key="answer.id"
          >
            <b-form-checkbox v-model="answer.isSelected">
              {{ answer.name }}
            </b-form-checkbox>
          </div>
        </div>

        <!-- Render input for open-ended questions (subtaskTypeId === 3) -->
        <div v-else-if="question.subtaskTypeId === 3">
          <label>{{ question.prompt }}</label>
          <b-form-input
            v-model="question.answer"
            :placeholder="question.prompt"
          />
        </div>
      </b-card>
    </div>

    <!-- Submit button -->
    <b-button
      variant="danger"
      class="mt-3 w-100"
      @click="handleSubmit"
    >
      Skicka in
    </b-button>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import constants from '@/constants/static.json'
import mixinAlert from '@/constants/mixinAlert'
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    BCard,
    BButton,
    BFormCheckbox,
    BFormInput,
  },
  mixins: [mixinDate],
  data() {
    return {
      content: {
        id: 0,
        externalReference: '',
        sourceContentId: null,
        contentStatusId: 0,
        contentFrequencyId: 0,
        contentTypeId: 0,
        projectId: 0,
        companyId: 0,
        length: 0,
        name: '',
        start: '',
        end: null,
        tag: '',
        language: '',
        chatEnabled: false,
        visibility: false,
        template: false,
        analyzeIndividual: false,
        description: constants.CONTENT_DESCRIPTION,
      },
      questionsWithAnswers: [],
      tasks: [],
      isSubmit: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState('content', ['allContents']),
    ...mapState('task', ['allContentTasks']),
  },
  watch: {
    // Watcher to recompute questionsWithAnswers when subtasks are updated
    'allContentTasks.subtasks': {
      handler(newValue) {
        if (newValue && newValue.length) {
          this.computeQuestionsWithAnswers()
        }
      },
      immediate: true,
    },
  },
  setup() {
    const { successMessage, showErrorMessage } = mixinAlert()
    return {
      successMessage,
      showErrorMessage,
    }
  },
  async created() {
    const { id } = this.$route.params
    if (id) {
      this.content.id = id
      try {
        const response = await this.fetchContentByID(this.content.id)
        if (response) {
          this.content = response.data
          const responseTask = await this.fetchContentTaskByID(this.content.id)
          if (responseTask) {
            // eslint-disable-next-line prefer-destructuring
            this.tasks = responseTask.data.data[0]
            this.computeQuestionsWithAnswers()
          }
        }
      } catch (error) {
        console.error('Error fetching content:', error)
      }
    }
  },
  methods: {
    ...mapActions('content', ['fetchContentByID']),
    ...mapActions('task', ['fetchContentTaskByID']),

    computeQuestionsWithAnswers() {
      // Ensure tasks and subtasks data is available
      if (!this.tasks || !this.tasks.subtasks) return

      // Get questions based on subtaskTypeId === 3 (open-ended) or subtaskTypeId === 5 (multiple-choice)
      const questions = this.tasks.subtasks.filter(subtask => subtask.subtaskTypeId === 3 || subtask.subtaskTypeId === 5)

      // Map each question to include related answers
      this.questionsWithAnswers = questions.map(question => ({
        ...question,
        answers: this.tasks.subtasks.filter(subtask => (
          // Include only actual answers (subtaskTypeId === 1)
          subtask.subtaskTypeId === 1
      // Find answers by matching tags for multiple-choice questions
      && ((subtask.tag === question.tag && question.tag !== null)
      // For open-ended questions with null tag, match by taskId
      || (question.tag === null && subtask.taskId === question.taskId))
        )),
      }))
    },
    handleSubmit() {
      // Collect responses for submission
      const responses = this.questionsWithAnswers.map(question => {
        const selectedAnswers = question.answers
          .filter(answer => answer.isSelected)
          .map(answer => answer.name)
        return {
          question: question.description || question.name,
          answer: question.subtaskTypeId === 3 ? question.answer || selectedAnswers : selectedAnswers,
        }
      })
      console.log('User Responses:', responses)
      this.successMessage('Responses submitted successfully!')
    },
  },
}
</script>

<style scoped>
.question-page {
  padding: 20px;
}

.custom-card {
  border: 2px solid #ff6400;
  border-radius: 10px;
}

.mt-3 {
  margin-top: 1rem;
}

.w-100 {
  width: 100%;
}
</style>
